// src/App.js
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Client } from "@microsoft/microsoft-graph-client";
import { loginRequest } from "./authConfig";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import QuickLinks from "./QuickLinks";

import sharepointicon from "./sharepointicon.png";
import synergistlogo from "./synergistlogo.jpeg";

import {
  sharepointlinkLogo,
  crh,
  hrhub,
  itsupport,
  mRoomLogo,
  pleo,
  syn,
  iCompleatLogo,
  xeroLogo,
  streamtimeLogo,
  lloydsLogo,
  fathomLogo,
  scribeLogo,
  filesharing,
  onedriveLogo,
} from "./images/images.js";

// Helper function to normalize URLs: trim, lowercase, remove trailing slashes.
const normalizeUrl = (url) => {
  if (!url) return "";
  return url.trim().toLowerCase().replace(/\/+$/, "");
};

const App = () => {
  // Variables for the Synergist API – replace these with your actual values.
  const sha512password = "3af06cb90efb810287f4279fef8063159959d66b62e9118a9221b44bb5294e3961838518697ea646e8585698096384cdaeb18c1aef3b1742fbc6a9cd4b93351c";
  const user = "apiread";
  const company = "1";
  const version = "3.9";

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // State variables.
  const [data, setData] = useState([]); // Raw Synergist API data.
  const [filteredData, setFilteredData] = useState([]); // Data after filtering (by allowed sites/search).
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [jobTitle, setJobTitle] = useState(null);
  const [allowedSites, setAllowedSites] = useState([]); // SharePoint sites from Search API.

  // State for context menu.
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    rowData: null,
  });

  // Function to handle login via MSAL.
  const handleLogin = async () => {
    try {
      await instance.loginPopup(loginRequest);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // Fetch the user's profile (e.g. to get jobTitle).
  const fetchUserProfile = async (accessToken) => {
    const client = Client.init({
      authProvider: (done) => {
        done(null, accessToken);
      },
    });
    try {
      const profile = await client.api("/me").get();
      setJobTitle(profile.jobTitle || "Not set");
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  // Fetch SharePoint sites using the Microsoft Graph Search API.
  // This returns all sites (up to the specified size) that the user can access.
  const fetchSharePointSites = async (accessToken) => {
    const client = Client.init({
      authProvider: (done) => {
        done(null, accessToken);
      },
    });
    try {
      const requestBody = {
        requests: [
          {
            entityTypes: ["site"],
            query: { queryString: "*" }, // Wildcard to return all accessible sites.
            from: 0,
            size: 100, // Adjust if you expect more than 100 sites.
          },
        ],
      };
      const response = await client.api("/search/query").post(requestBody);
      let sites = [];
      if (response && response.value && response.value.length > 0) {
        response.value.forEach((container) => {
          if (container.hitsContainers && container.hitsContainers.length > 0) {
            container.hitsContainers.forEach((hitsContainer) => {
              if (hitsContainer.hits && hitsContainer.hits.length > 0) {
                hitsContainer.hits.forEach((hit) => {
                  if (hit.resource && hit.resource.webUrl) {
                    sites.push(hit.resource);
                  }
                });
              }
            });
          }
        });
      }
      console.log("SharePoint sites from Search API:", sites);
      return sites;
    } catch (error) {
      console.error("Error fetching SharePoint sites using Search API:", error);
      return [];
    }
  };

  // When the user is authenticated, fetch the user profile, allowed SharePoint sites, and Synergist API data.
  useEffect(() => {
    if (isAuthenticated) {
      const fetchAllData = async () => {
        try {
          const account = accounts[0];
          if (account) {
            const tokenResponse = await instance.acquireTokenSilent({
              ...loginRequest,
              account,
            });
            // Fetch the user's profile.
            await fetchUserProfile(tokenResponse.accessToken);
            // Fetch SharePoint sites.
            const sites = await fetchSharePointSites(tokenResponse.accessToken);
            setAllowedSites(sites);
          }

          // Fetch Synergist API data.
          const response = await fetch(
            `https://camarguegroup.synergist.cloud/jsonapi/jobs.json?modelstructure=jobslist&sha512password=${sha512password}&user=${user}&company=${company}&version=${version}`
          );
          const result = await response.json();
          console.log("Synergist API data:", result.data);
          setData(result.data || []);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching data:", err);
          setError("Failed to fetch data");
          setLoading(false);
        }
      };

      fetchAllData();
    }
  }, [isAuthenticated, accounts, instance]);

  // Filter the Synergist data.
  // If the user's jobTitle includes "finance", is "it manager", or is "chief technology officer",
  // show all data (applying only search filtering).
  // Otherwise, filter based on allowed SharePoint site URLs.
  useEffect(() => {
    if (!data || data.length === 0) {
      setFilteredData([]);
      return;
    }
    const lowerSearch = search.toLowerCase();
    const lowerJobTitle = jobTitle ? jobTitle.toLowerCase() : "";
    if (
      lowerJobTitle.includes("finance") ||
      lowerJobTitle === "it manager" ||
      lowerJobTitle === "chief technology officer"
    ) {
      // These users see all entries (only search filtering applied).
      const finalFiltered = data.filter((item) => {
        return (
          (item.jobNumberSimple || "").toLowerCase().includes(lowerSearch) ||
          (item.jobClientCode || "").toLowerCase().includes(lowerSearch) ||
          (item.jobDescription1stLine || "").toLowerCase().includes(lowerSearch) ||
          (item.jobClientName || "").toLowerCase().includes(lowerSearch) ||
          (item.jobHandlerFullName || "").toLowerCase().includes(lowerSearch) ||
          (item.jobCustomField6 || "").toLowerCase().includes(lowerSearch) ||
          (item.jobCompleted || "").toString().toLowerCase().includes(lowerSearch)
        );
      });
      console.log("Finance/IT/CTO user – final filtered data:", finalFiltered);
      setFilteredData(finalFiltered);
    } else {
      // For non-finance and non-IT/CTO users: filter by allowed SharePoint site URLs.
      if (allowedSites && allowedSites.length > 0) {
        const allowedUrls = new Set(allowedSites.map((site) => normalizeUrl(site.webUrl)));
        console.log("Allowed URLs:", Array.from(allowedUrls));
        const accessFiltered = data.filter((item) => {
          const siteUrl = normalizeUrl(item.jobCustomField6);
          return allowedUrls.has(siteUrl);
        });
        const finalFiltered = accessFiltered.filter((item) => {
          return (
            (item.jobNumberSimple || "").toLowerCase().includes(lowerSearch) ||
            (item.jobClientCode || "").toLowerCase().includes(lowerSearch) ||
            (item.jobDescription1stLine || "").toLowerCase().includes(lowerSearch) ||
            (item.jobClientName || "").toLowerCase().includes(lowerSearch) ||
            (item.jobHandlerFullName || "").toLowerCase().includes(lowerSearch) ||
            (item.jobCustomField6 || "").toLowerCase().includes(lowerSearch) ||
            (item.jobCompleted || "").toString().toLowerCase().includes(lowerSearch)
          );
        });
        console.log("Non-finance user – final filtered data:", finalFiltered);
        setFilteredData(finalFiltered);
      } else {
        setFilteredData([]);
      }
    }
  }, [search, data, allowedSites, jobTitle]);

  // Handle row click to display the context menu.
  const handleRowClick = (row, event) => {
    // Prevent the default click behavior.
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      rowData: row,
    });
  };

  // Hide the context menu when clicking outside.
  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.visible) {
        setContextMenu((c) => (c.visible ? { ...c, visible: false } : c));
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu.visible]);

  // Define columns for the DataTable.
  const columns = [
    {
      id: "sharepointLink",
      name: "SharePoint Link",
      selector: (row) => row.jobCustomField6,
      sortable: true,
      width: "60px",
      cell: (row) =>
        row.jobCustomField6 ? (
          <a href={row.jobCustomField6} target="_blank" rel="noopener noreferrer">
            <img
              src="shpt.png"
              alt="SharePoint Link"
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
            />
          </a>
        ) : null,
    },
    {
      name: "Job Number",
      selector: (row) => row.jobNumberSimple,
      sortable: true,
      width: "100px",
      cell: (row) =>
        row.jobNumberSimple ? (
          <a href="https://camarguegroup.synergist.cloud/" target="_blank" rel="noopener noreferrer">
            {row.jobNumberSimple}
          </a>
        ) : null,
    },
    {
      name: "Client Code",
      selector: (row) => row.jobClientCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Description",
      selector: (row) => row.jobDescription1stLine,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row.jobClientName,
      sortable: true,
    },
    {
      name: "Job Handler",
      selector: (row) => row.jobHandlerFullName,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.jobCompleted,
      sortable: true,
      width: "100px", // Reduced width for the status column.
      cell: (row) =>
        row.jobCompleted ? (
          <span
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "2px 8px",
              borderRadius: "5px",
              fontSize: "0.9rem",
            }}
          >
            Closed
          </span>
        ) : (
          <span
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "2px 8px",
              borderRadius: "5px",
              fontSize: "0.9rem",
            }}
          >
            Open
          </span>
        ),
    },
  ];

  if (!isAuthenticated) {
    return (
      <div className="login-container">
        <img src="logo.png" alt="Logo" className="logo-login" />
        <h1>Please sign in to access the application</h1>
        <button onClick={handleLogin} className="btn btn-primary">
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div>
      <header className="app-header">
        <img src="logo.png" alt="Logo" className="logo" />
        {isAuthenticated && (
          <div>
            <span>
              Welcome: {accounts[0].name}, {jobTitle || "Loading..."}
            </span>
          </div>
        )}
      </header>

      <div className="container mt-3">
        <QuickLinks
          links={[
            ...(jobTitle && jobTitle.toLowerCase().includes("finance")
              ? [
                  {
                    title: "Xero",
                    url: "https://login.xero.com/identity/user/login?...",
                    image: xeroLogo,
                  },
                ]
              : []),
            {
              title: "Synergist",
              url: "https://camarguegroup.synergist.cloud/",
              image: syn,
            },
            ...(jobTitle && jobTitle.toLowerCase().includes("finance")
              ? [
                  {
                    title: "StreamTime",
                    url: "https://jamesforddesignteam.app.streamtime.net/#dashboard",
                    image: streamtimeLogo,
                  },
                ]
              : []),
            ...((jobTitle &&
              jobTitle.toLowerCase().includes("finance"))
              ? [
                  {
                    title: "iCompleat",
                    url: "https://portal.compleat.online/",
                    image: iCompleatLogo,
                  },
                ]
              : []),
            {
              title: "Pleo",
              url: "https://app.pleo.io/login",
              image: pleo,
            },
            ...(jobTitle && jobTitle.toLowerCase().includes("finance")
              ? [
                  {
                    title: "Lloyds",
                    url: "https://cbonline.lloydsbank.com/PrimaryAuth/",
                    image: lloydsLogo,
                  },
                  {
                    title: "Fathom",
                    url: "https://app.fathomhq.com/login?ReturnUrl=%2fmy-companies",
                    image: fathomLogo,
                  },
                  {
                    title: "Scribe",
                    url: "https://scribehow.com/workspace#dashboard",
                    image: scribeLogo,
                  },
                ]
              : []),
            {
              title: "HR Hub",
              url: "https://camargue.sharepoint.com/sites/HR_Hub/",
              image: hrhub,
            },
            {
              title: "Central Resources",
              url: "https://camargue.sharepoint.com/sites/CRH",
              image: crh,
            },
            {
              title: "SharePoint Home",
              url: "https://camargue.sharepoint.com/_layouts/15/sharepoint.aspx",
              image: sharepointlinkLogo,
            },
            {
              title: "OneDrive Home",
              url: "https://camargue-my.sharepoint.com/",
              image: onedriveLogo,
            },
            {
              title: "Internal File Share",
              url: "https://camargue.sharepoint.com/sites/TransferTransfersite/",
              image: filesharing,
            },
            {
              title: "Room Booking",
              url: "https://apps.powerapps.com/play/e/default-46d4e256-b3be-4371-9b3c-480cbc8d7489/a/f95044d6-4723-4790-867e-3d05365ddfb3?tenantId=46d4e256-b3be-4371-9b3c-480cbc8d7489&source=sharebutton&sourcetime=1723220431195#",
              image: mRoomLogo,
            },
            {
              title: "IT Guidance",
              url: "https://camargue.sharepoint.com/sites/HR_Hub/SitePages/ITSupport.aspx",
              image: itsupport,
            },
          ]}
        />
      </div>

      <div className="container mt-3">
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search clients..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <span class="search-help">Search terms can match any column from the table below.</span>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            paginationPerPage={10}
            highlightOnHover
            pointerOnHover
            defaultSortFieldId="sharepointLink"
            defaultSortAsc={false}
            onRowClicked={handleRowClick}
          />
        )}
      </div>

      {/* Context Menu */}
      {contextMenu.visible && contextMenu.rowData && (
        <div
          className="context-menu"
          style={{
            position: "fixed",
            top: contextMenu.y,
            left: contextMenu.x,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            zIndex: 9999,
            boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent menu clicks from closing the menu immediately.
        >

{/* Non-clickable header showing the row's description */}
<div
            className="context-menu-title"
            style={{
              padding: "8px 12px",
              fontWeight: "bold",
              borderBottom: "1px solid #ccc",
            }}
          >
            {contextMenu.rowData.jobDescription1stLine}
          </div>
{/* Render SharePoint menu item only if jobCustomField6 exists */}
{contextMenu.rowData.jobCustomField6 && (
          <div
            className="context-menu-item"
            style={{ padding: "8px 12px", cursor: "pointer" }}
            onClick={() => {
              // Option: Go to SharePoint site.
              if (contextMenu.rowData.jobCustomField6) {
                window.open(contextMenu.rowData.jobCustomField6, "_blank");
              }
              setContextMenu({ ...contextMenu, visible: false });
            }}
          >
            <img
              src={sharepointicon}
              alt="SharePoint Icon"
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />
            Go to SharePoint site
          </div>
)}
          <div
            className="context-menu-item"
            style={{ padding: "8px 12px", cursor: "pointer" }}
            onClick={() => {
              // Option: Go to Synergist with the job number.
              const jobNumber = contextMenu.rowData.jobNumberSimple;
              window.open(
                `https://camarguegroup.synergist.cloud/?job=${jobNumber}`,
                "_blank"
              );
              setContextMenu({ ...contextMenu, visible: false });
            }}
          >
            <img
              src={synergistlogo}
              alt="Synergist Logo"
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />
            Go to Synergist - {contextMenu.rowData.jobNumberSimple}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
