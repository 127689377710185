// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig"; // OPS (portal.camargue.uk)
// import { msalConfig } from "./authConfig-local"; // DEV (localhost:3000)
// import { msalConfig } from "./authConfig-v2"; // OPSv2 (v2.portal.camargue.uk)

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  document.getElementById("root")
);

